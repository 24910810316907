import React from "react";

function CheckboxLetter({ label, isSelected, onCheckboxChange, category }) {
  return (
    <>
      <input
        type="checkbox"
        name={label.id}
        checked={isSelected}
        onChange={onCheckboxChange}
        className="m-1 w-[10px] h-[10px]"
        value={category}
      />
      <label className="w-4 h-3 mx-0.5">{label.text}</label>
      <br />
    </>
  );
}

export default CheckboxLetter;
