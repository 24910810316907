import React, { Suspense } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./Routes/Routes.js";
import { HelmetProvider } from "react-helmet-async";
import "./App.css";

function App() {
  return (
    <HelmetProvider>
      <Router>
        <Suspense>
          <Routes />
        </Suspense>
      </Router>
    </HelmetProvider>
  );
}

export default App;
