import React from "react";
import { Helmet } from "react-helmet-async";

function SEO({ title, description }) {
  console.log(title);
  console.log(description);
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
    </Helmet>
  );
}

export default SEO;
