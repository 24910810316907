function multiPropsFilter(products, filters) {
  const keyToIndex = {
    genders: 2,
    origins: 4,
    religions: 5,
    alphabets: 0,
  };
  const filterKeys = Object.keys(filters);
  console.log("inside multipropfilter");
  console.log(filterKeys);
  return products.filter((product) => {
    return filterKeys.every((key) => {
      console.log("inside return key " + key);
      if (!filters[key].length) {
        return true;
      }
      if (key === "alphabets") {
        return filters[key].includes(
          product[keyToIndex[key]].charAt(0).toLowerCase()
        );
        // console.log(product[keyToIndex[key]].charAt(0));
      }
      return filters[key].includes(product[keyToIndex[key]].toLowerCase());
    });
  });
  //   return products.filter((product) => {
  //     return filterKeys.every((key) => {
  //       if (!filters[key].length) return true;
  //       // Loops again if product[key] is an array (for material attribute).
  //       if (Array.isArray(product[key])) {
  //         return product[key].some((keyEle) => filters[key].includes(keyEle));
  //       }
  //       return filters[key].includes(product[key]);
  //     });
  //   });
}

export default multiPropsFilter;
