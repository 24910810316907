import React, { useState } from "react";
import { Link } from "react-router-dom";
import { logo } from "../../../Constants/IconUrl";

function HeaderComponent() {
  const [open, setOpen] = useState(false);
  return (
    <header className="sticky top-0 text-black-700 text-base bg-zinc-100 rounded-md bg-clip-padding backdrop-filter backdrop-blur-xl bg-opacity-40 border border-gray-100">
      <div className=" mx-4 lg:mx-6">
        <nav className="flex justify-between flex-wrap">
          <Link to="/">
            <img
              src={logo}
              alt="logo"
              className=" w-[170px] md:w-52 md:h-12 my-4 h-auto"
            />
          </Link>
          <div className="block lg:hidden">
            <button
              id="nav-toggle"
              className=" px-3 py-2 mt-6 border rounded  border-black-700 hover:border-black-500 flex items-center"
              onClick={() => setOpen(!open)}
            >
              <svg
                className="fill-current h-3 w-3"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Menu</title>
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
              </svg>
            </button>
          </div>
          <div
            className={`${
              open ? "block" : "hidden"
            } w-full lg:flex lg:items-center flex-grow lg:w-auto`}
          >
            <ul className="lg:flex justify-end flex-1 items-center">
              <li className="lg:mr-4 lg:m-5 my-4 ml-2">
                <Link to="/" className="hover:text-black-500">
                  Blog
                </Link>
              </li>
              <li className="lg:mr-4 lg:m-5 my-4 ml-2">
                <Link to="/" className="hover:text-black-500">
                  Home
                </Link>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </header>
  );
}

export default HeaderComponent;
