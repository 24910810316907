const genders = [
  { id: "boy", text: "Boy" },
  { id: "girl", text: "Girl" },
];
const religions = [
  { id: "hindu", text: "Hindu" },
  { id: "christian", text: "Christian" },
];
const origins = [
  { id: "hindi", text: "Hindi" },
  { id: "sanskrit", text: "Sanskrit" },
];

const alphabets = [
  { id: "a", text: "A" },
  { id: "b", text: "B" },
  { id: "c", text: "C" },
  { id: "d", text: "D" },
  { id: "e", text: "E" },
  { id: "f", text: "F" },
  { id: "g", text: "G" },
  { id: "h", text: "H" },
  { id: "i", text: "I" },
  { id: "j", text: "J" },
  { id: "k", text: "K" },
  { id: "l", text: "L" },
  { id: "m", text: "M" },
  { id: "n", text: "N" },
  { id: "o", text: "O" },
  { id: "p", text: "P" },
  { id: "q", text: "Q" },
  { id: "r", text: "R" },
  { id: "s", text: "S" },
  { id: "t", text: "T" },
  { id: "u", text: "U" },
  { id: "v", text: "V" },
  { id: "w", text: "W" },
  { id: "x", text: "X" },
  { id: "y", text: "Y" },
  { id: "z", text: "Z" },
];
export { genders, religions, origins, alphabets };
