import React from "react";
import { Link } from "react-router-dom";
import { logo } from "../../../Constants/IconUrl";

function FooterComponent() {
  return (
    <footer className="bg-gradient-to-b from-blue_magenta-400/80 to-magenta-400/80 h-48 text-black-500">
      <div className="mx-4 lg:mx-6">
        <div>
          <Link class="flex " to="/">
            <img
              src={logo}
              alt="logo"
              className="w-[170px] md:w-52 md:h-12 mt-12 h-auto"
            />
          </Link>
          <div className="text-sm  py-2 pl-1">
            Copyright © {new Date().getFullYear()} Baby Names 101. All rights
            reserved
          </div>
        </div>
      </div>
    </footer>
  );
}

export default FooterComponent;
