// **************** Collect all keys and Filter ****************
// This function collects ALL keys that have true as a value, then create a new obj to compare to filter.
function FilteredCollected(passedfilters) {
  const collectedTrueKeys = {
    genders: [],
    religions: [],
    origins: [],
    alphabets: [],
  };
  const { genders, religions, origins, alphabets } = passedfilters;
  for (let genderKey in genders) {
    if (genders[genderKey]) collectedTrueKeys.genders.push(genderKey);
  }
  for (let religionKey in religions) {
    if (religions[religionKey]) collectedTrueKeys.religions.push(religionKey);
  }
  for (let originKey in origins) {
    if (origins[originKey]) collectedTrueKeys.origins.push(originKey);
  }
  for (let alphabetKey in alphabets) {
    if (alphabets[alphabetKey]) collectedTrueKeys.alphabets.push(alphabetKey);
  }
  console.log("inside filterecollected");
  console.log(collectedTrueKeys);
  return collectedTrueKeys;
}

export default FilteredCollected;
