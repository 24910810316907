const babyBoy =
  "https://res.cloudinary.com/mockoncall/image/upload/v1666936928/BabyNames/Baby-Names-Boy.png";

const babyGirl =
  "https://res.cloudinary.com/mockoncall/image/upload/v1666936928/BabyNames/Baby-Names-Girl.png";

const nameMeaning =
  "https://res.cloudinary.com/mockoncall/image/upload/v1666936929/BabyNames/Baby-Names_Meaning.png";

const phoneticSpell =
  "https://res.cloudinary.com/mockoncall/image/upload/v1666936929/BabyNames/Baby-Names-Phonetic-Spelling.png";

const pronounciation =
  "https://res.cloudinary.com/mockoncall/image/upload/v1666936929/BabyNames/Baby-Names-Pronounciation.png";

const origin =
  "https://res.cloudinary.com/mockoncall/image/upload/v1666936929/BabyNames/Baby-Names-Origin.png";
const hindu =
  "https://res.cloudinary.com/mockoncall/image/upload/v1666936928/BabyNames/Baby-Names-Religion-Hindu.png";
const name =
  "https://res.cloudinary.com/mockoncall/image/upload/v1666936928/BabyNames/Baby_Names_128_qdbixd.png";
const logo =
  "https://res.cloudinary.com/mockoncall/image/upload/v1668497099/BabyNames/Baby-Names-Logo.png";
export {
  babyBoy,
  babyGirl,
  nameMeaning,
  phoneticSpell,
  pronounciation,
  origin,
  name,
  hindu,
  logo,
};
