import React from "react";
import NameCardComponent from "../../Common/Card/NameCardComponent";

function NameListComponent({ nameList }) {
  console.log("inside NameListComponent");
  console.log(nameList);
  return (
    <main className="flex flex-wrap justify-evenly md:justify-start">
      {nameList.map((name, index) => (
        <NameCardComponent nameprop={name} key={name[1]} />
      ))}
    </main>
  );
}

export default NameListComponent;

// index > 0 ? <NameCardComponent nameprop={name} key={name[1]} /> : <></>
