import React from "react";

function Checkboxmobile({ label, isSelected, onCheckboxChange, category }) {
  return (
    <div className="form-check">
      <label key={label.id}>
        <input
          type="checkbox"
          name={label.id}
          checked={isSelected}
          onChange={onCheckboxChange}
          className="m-1 w-[12px] h-[12px]"
          value={category}
        />
        {label.text}
      </label>
      <br />
    </div>
  );
}

export default Checkboxmobile;
