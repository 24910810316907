import React, { useState } from "react";
import { Link } from "react-router-dom";

function MobileFilter() {
  const [genders] = useState([
    { id: "boy", text: "Boy" },
    { id: "girl", text: "Girl" },
  ]);
  const [religions] = useState([
    { id: "hindu", text: "Hindu" },
    { id: "christian", text: "Christian" },
  ]);
  const [origins] = useState([
    { id: "hindi", text: "Hindi" },
    { id: "sanskrit", text: "Sanskrit" },
  ]);
  const [alphabets] = useState([
    { id: "a", text: "A" },
    { id: "b", text: "B" },
    { id: "c", text: "C" },
    { id: "d", text: "D" },
    { id: "e", text: "E" },
    { id: "f", text: "F" },
    { id: "g", text: "G" },
    { id: "h", text: "H" },
    { id: "i", text: "I" },
    { id: "j", text: "J" },
    { id: "k", text: "K" },
    { id: "l", text: "L" },
    { id: "m", text: "M" },
    { id: "n", text: "N" },
    { id: "o", text: "O" },
    { id: "p", text: "P" },
    { id: "q", text: "Q" },
    { id: "r", text: "R" },
    { id: "s", text: "S" },
    { id: "t", text: "T" },
    { id: "u", text: "U" },
    { id: "v", text: "V" },
    { id: "w", text: "W" },
    { id: "x", text: "X" },
    { id: "y", text: "Y" },
    { id: "z", text: "Z" },
  ]);
  return (
    <div>
      <div className="lg:hidden fixed inset-x-0 bottom-0 z-10 mr-auto bg-gradient-to-b from-black-400 to-black-400/40 h-8">
        <div className="text-xs md:text-base text-center flex">
          <button
            id="nav-toggle"
            className="flex justify-center item-center w-1/2 border-r-2"
          >
            <Link to="/" className="py-1.5 px-1 md:p-1">
              CLOSE
            </Link>
          </button>
          <button
            id="nav-toggle"
            className="flex justify-center item-center w-1/2"
          >
            <Link to="/" className="py-1.5 px-1 md:p-1">
              APPLY
            </Link>
          </button>
        </div>
      </div>
      <div
        className="
         bg-slate-50 w-full lg:hidden"
      >
        <div className="my-2 mx-4 md:my-2.5 md:mx-6 ">
          <h6 className="ml-0.5 text-sm font-semibold">Gender</h6>
          <div className="my-1 text-xs">
            {genders.map((gender, index) => (
              <>
                <input
                  type="checkbox"
                  id={gender.id}
                  name={gender.id}
                  value={gender.id}
                  className="m-1.5 w-[12px] h-[12px]"
                />
                <label htmlFor={gender.id}>{gender.text}</label>
                <br />
              </>
            ))}
          </div>
        </div>
        <div className=" my-2 mx-4 md:my-2.5 md:mx-6">
          <h6 className="text-sm ml-0.5 font-semibold mt-2">Religion</h6>
          <div className="my-1  text-xs">
            {religions.map((religion, index) => (
              <>
                <input
                  type="checkbox"
                  id={religion.id}
                  name={religion.id}
                  value={religion.id}
                  className="m-1.5 w-[12px] h-[12px]"
                />
                <label htmlFor={religion.id}>{religion.text}</label>
                <br />
              </>
            ))}
          </div>
        </div>
        <div className=" my-2 mx-4 md:my-2.5 md:mx-6">
          <h6 className="text-sm ml-0.5 font-semibold mt-2">Origin</h6>
          <div className="my-1 text-xs">
            {origins.map((origin, index) => (
              <>
                <input
                  type="checkbox"
                  id={origin.id}
                  name={origin.id}
                  value={origin.id}
                  className="m-1.5 w-[12px] h-[12px]"
                />
                <label htmlFor={origin.id}>{origin.text}</label>
                <br />
              </>
            ))}
          </div>
        </div>
        <div className="my-2 mx-4 w-64 md:my-2.5 md:mx-6 md:w-64">
          <h6 className="text-sm ml-0.5 font-semibold mt-2"> Letter</h6>
          <div className=" text-xs flex justify-start flex-wrap lg:w-4/12 m-1 ml-2">
            {alphabets.map((alphabet, index) => (
              <>
                <input
                  type="checkbox"
                  id={alphabet.id}
                  name={alphabet.id}
                  value={alphabet.id}
                  className="m-1 w-[12px] h-[12px]"
                />
                <label htmlFor={alphabet.id} className="w-4 h-3 mx-0.5">
                  {alphabet.text}
                </label>
                <br />
              </>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MobileFilter;
