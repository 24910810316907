import React, { useState } from "react";
// import { Link } from "react-router-dom";
import { MdFilterListAlt } from "react-icons/md";
import {
  genders,
  religions,
  origins,
  alphabets,
} from "../../../Constants/FilterItems/FilterItems";
import Checkbox from "../../Common/Checkbox/Checkbox";
import CheckboxLetter from "../../Common/Checkbox/CheckboxLetter";
import Checkboxmobile from "../../Common/Checkbox/Checkboxmobile";
import CheckboxmobileLetter from "../../Common/Checkbox/CheckboxmobileLetter";

function SideBarComponent1({ handleFilterSubmit }) {
  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState({
    genders: {
      boy: false,
      girl: false,
    },
    religions: {
      hindu: false,
      christian: false,
    },
    origins: {
      hindi: false,
      sanskrit: false,
    },
    alphabets: {
      a: false,
      b: false,
      c: false,
      d: false,
      e: false,
      f: false,
      g: false,
      h: false,
      i: false,
      j: false,
      k: false,
      l: false,
      m: false,
      n: false,
      o: false,
      p: false,
      q: false,
      r: false,
      s: false,
      t: false,
      u: false,
      v: false,
      w: false,
      x: false,
      y: false,
      z: false,
    },
  });
  const handleCheckboxChange = (changeEvent) => {
    const { name, value } = changeEvent.target;
    console.log("inside handle check");
    console.log(name);
    console.log(value);
    // console.log(checked[value][name]);
    setChecked((prevchecks) => {
      console.log(prevchecks);
      // console.log(prevchecks[value]);
      return {
        ...prevchecks,
        [value]: { ...prevchecks[value], [name]: !prevchecks[value][name] },
      };
    });
    console.log("outside handlechange " + checked);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("inside handle Submit");
    console.log(state.button);
    if (state.button === 1) {
      handleFilterSubmit(checked);
    }
    if (state.button === 2) {
      setOpen(!open);
    }
  };
  //to figure out which button submitted form
  const state = {
    button: 1,
  };

  return (
    <aside>
      <div className="rounded-xl shadow-sm shadow-black-400 hidden lg:block lg:w-48">
        <div className="text-base font-medium rounded-t-lg bg-gradient-to-b from-black-400 to-black-400/40  flex">
          <MdFilterListAlt className="mt-3 ml-2" />
          <p className="text-base font-medium py-2 px-1">Filters</p>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="p-2">
            <p className="ml-0.5 text-sm font-semibold">Gender</p>
            <div className="m-1 ml-2 text-xs ">
              {genders.map((gender_temp) => (
                <Checkbox
                  label={gender_temp}
                  isSelected={checked.genders[gender_temp.id]}
                  onCheckboxChange={handleCheckboxChange}
                  key={gender_temp.id}
                  category="genders"
                />
              ))}
            </div>
            <p className="text-sm ml-0.5 font-semibold mt-2">Religion</p>
            <div className="m-1 ml-2 text-xs">
              {religions.map((religion_temp) => (
                <Checkbox
                  label={religion_temp}
                  isSelected={checked.religions[religion_temp.id]}
                  onCheckboxChange={handleCheckboxChange}
                  key={religion_temp.id}
                  category="religions"
                />
              ))}
            </div>
            <p className="text-sm ml-0.5 font-semibold mt-2">Origin</p>
            <div className="m-1 ml-2 text-xs">
              {origins.map((origin_temp) => (
                <Checkbox
                  label={origin_temp}
                  isSelected={checked.origins[origin_temp.id]}
                  onCheckboxChange={handleCheckboxChange}
                  key={origin_temp.id}
                  category="origins"
                />
              ))}
            </div>
            <p className="text-sm ml-0.5 font-semibold mt-2">Letter</p>
            <div className=" text-xs lg:flex justify-start flex-wrap lg:w-8/12 m-1 ml-2">
              {alphabets.map((alphabet_temp) => (
                <CheckboxLetter
                  label={alphabet_temp}
                  isSelected={checked.alphabets[alphabet_temp.id]}
                  onCheckboxChange={handleCheckboxChange}
                  key={alphabet_temp.id}
                  category="alphabets"
                />
              ))}
            </div>
            <button
              className="text-[11px] font-bold rounded bg-black-400 m-0.5 px-4 py-1"
              name="btn"
              value="apply"
              onClick={() => (state.button = 1)}
            >
              APPLY
            </button>
          </div>
        </form>
      </div>
      {/* filter For Mobile and Medium Devices */}
      <div
        className={`${
          open ? "block" : "hidden"
        } rounded-xl border border-black-400 shadow-sm shadow-black-400 bg-slate-50 w-3/4 md:w-2/3 h-4/5  fixed inset-x-2 bottom-6 lg:hidden overflow-y-auto journal-scroll `}
      >
        <form onSubmit={handleSubmit}>
          <div className="my-4 mx-6 md:my-6 md:mx-8 ">
            <p className="ml-0.5 text-sm font-semibold">Gender</p>
            <div className="my-1 text-xs ml-2">
              {genders.map((gender_temp) => (
                <Checkboxmobile
                  label={gender_temp}
                  isSelected={checked.genders[gender_temp.id]}
                  onCheckboxChange={handleCheckboxChange}
                  key={gender_temp.id}
                  category="genders"
                />
              ))}
            </div>
            <p className="text-sm ml-0.5 font-semibold mt-2">Religion</p>
            <div className="my-1  text-xs ml-2">
              {religions.map((religion_temp) => (
                <Checkboxmobile
                  label={religion_temp}
                  isSelected={checked.religions[religion_temp.id]}
                  onCheckboxChange={handleCheckboxChange}
                  key={religion_temp.id}
                  category="religions"
                />
              ))}
            </div>
            <p className="text-sm ml-0.5 font-semibold mt-2">Origin</p>
            <div className="my-1 text-xs ml-2">
              {origins.map((origin_temp) => (
                <Checkboxmobile
                  label={origin_temp}
                  isSelected={checked.origins[origin_temp.id]}
                  onCheckboxChange={handleCheckboxChange}
                  key={origin_temp.id}
                  category="origins"
                />
              ))}
            </div>
            <p className="text-sm ml-0.5 font-semibold mt-2"> Letter</p>
            <div className=" text-xs flex justify-start flex-wrap lg:w-8/12 m-1 ml-2 w-[160px] ">
              {alphabets.map((alphabet_temp) => (
                <CheckboxmobileLetter
                  label={alphabet_temp}
                  isSelected={checked.alphabets[alphabet_temp.id]}
                  onCheckboxChange={handleCheckboxChange}
                  key={alphabet_temp.id}
                  category="alphabets"
                />
              ))}
            </div>
            <button
              className="text-[11px] font-bold rounded bg-black-400 m-0.5 px-4 py-1 my-2"
              name="btn"
              value="apply"
              onClick={() => (state.button = 1)}
            >
              APPLY
            </button>
            <button
              className="text-[11px] font-bold rounded bg-black-400 m-0.5 px-4 py-1 my-2 mx-6 "
              name="btn"
              value="close"
              onClick={() => {
                state.button = 2;
              }}
            >
              CLOSE
            </button>
          </div>
        </form>
      </div>
      <button onClick={() => setOpen(!open)}>
        <div className="lg:hidden fixed inset-x-0 bottom-0 z-10 mr-auto bg-gradient-to-b from-black-400 to-black-400/40 h-8">
          <div className="text-sm md:text-base text-center flex justify-center">
            <div id="nav-toggle" className="flex justify-start item-center">
              <MdFilterListAlt className="mt-2.5 md:mt-2" />
              <span class="py-1.5 px-1 md:p-1">Filters</span>
            </div>
          </div>
        </div>
      </button>
    </aside>
  );
}

export default SideBarComponent1;
