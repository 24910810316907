import React from "react";
import { BiFemaleSign, BiMaleSign } from "react-icons/bi";
import {
  babyBoy,
  babyGirl,
  hindu,
  phoneticSpell,
  pronounciation,
  nameMeaning,
  origin,
} from "../../../Constants/IconUrl";
function NameCardComponent({ nameprop }) {
  console.log("inside NameCardComponent");
  console.log(nameprop);
  return (
    <div className="lg:w-1/3  md:w-1/2 mb-6 md:pl-4">
      <div
        className={`${
          nameprop[2] === "Boy"
            ? "shadow-blue_magenta-400"
            : "shadow-magenta-400"
        } w-72 h-80 bg-white rounded-xl shadow-sm`}
      >
        <div
          className={`${
            nameprop[2] === "Boy"
              ? "from-blue_magenta-400 to-blue_magenta-400/40"
              : "from-magenta-400 to-magenta-400/40"
          }  py-2 px-4  rounded-t-xl  bg-gradient-to-b`}
        >
          <h3 className="text-[22px] font-[1000]">{nameprop[0]}</h3>
        </div>
        <div className="flex justify-center items-center mt-3">
          {nameprop[2] === "Boy" ? (
            <div className="flex">
              <img
                src={babyBoy}
                alt="Baby-Names-Boy"
                className="w-4 h-4 mt-1"
              />
              <span className="mt-1 ml-1.5 text-sm font-semibold">Boy</span>
            </div>
          ) : (
            <div className="flex">
              <img
                src={babyGirl}
                alt="Baby-Names-Girl"
                className="w-4 h-4 mt-1"
              />
              <span className="mt-1 ml-1 text-sm font-semibold">Girl</span>
            </div>
          )}
          {nameprop[5] === "Hindu" ? (
            <div className="flex">
              <img
                src={hindu}
                alt="Baby-Names-Religion-Hindu"
                className="w-4 h-4 ml-6 mt-1"
              />
              <span className="mt-1 ml-1 text-sm font-semibold">Hindu</span>
            </div>
          ) : (
            <div className="flex"></div>
          )}
        </div>
        <div className="mt-4">
          <div className="flex">
            <img
              src={pronounciation}
              alt="Baby-Names-Pronounciation"
              className="w-4 h-4 ml-3 mt-1"
            />
            <span className="mt-0.5 ml-1.5 text-sm font-semibold">
              Pronounciation:
            </span>
            <span className="mt-0.5 ml-5 text-sm font-medium text-black-500">
              {nameprop[6]}
            </span>
          </div>
          <div className="flex">
            <img
              src={phoneticSpell}
              alt="Baby-Names-Phonetic-Spelling"
              className="w-4 h-4 ml-3 mt-1"
            />
            <span className="mt-0.5 ml-1.5 text-sm font-semibold">
              Phonetic Spelling:
            </span>
            <span className="mt-0.5 ml-2 text-sm font-medium text-black-500">{`[ ${nameprop[7]} ]`}</span>
          </div>
        </div>
        <div className="mt-4">
          <div className="flex">
            <img
              src={nameMeaning}
              alt="Baby-Names-Meaning"
              className="w-4 h-4 ml-3 mt-1"
            />
            <span className="mt-0.5 ml-1.5 text-sm font-semibold ">
              Name Meaning
            </span>
          </div>
          <div className="text-sm ml-9 mt-1 text-left h-[92px] text-black-500 pr-1">
            {nameprop[3]}
          </div>
        </div>
        <div className="flex mt-0.5 justify-between">
          <div className="flex">
            <img
              src={origin}
              alt="Baby-Names-Origin"
              className="w-4 h-4 ml-3 mt-1"
            />
            <span className="mt-0.5 ml-1.5 text-sm font-semibold">Origin:</span>
            <span className="mt-0.5 ml-2 text-sm font-semibold">
              {nameprop[4]}
            </span>
          </div>
          <div className="mt-1.5">
            {nameprop[2] === "Boy" ? (
              <BiMaleSign className="text-blue_magenta-500  text-xl  mr-2" />
            ) : (
              <BiFemaleSign className="text-magenta-500  text-xl mr-2" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default NameCardComponent;
