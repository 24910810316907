import React from "react";
import wave_large from "../../Assets/SVG/baby_names_background_wave_large.svg";
import wave_small from "../../Assets/SVG/baby_names_background_wave_small.svg";

function HomeLandingComponent() {
  return (
    <div>
      <div className="relative"></div>
      <img
        src={wave_large}
        alt="logo"
        className="-z-10 absolute hidden md:block"
      />
      <img
        src={wave_small}
        alt="logo"
        className="-z-10 absolute  block md:hidden"
      />
      <div className=" flex flex-col items-center justify-center ">
        <h1 className=" text-black-700 font-[1000] text-3xl md:text-4xl lg:text-5xl mt-[35px] md:mt-[45px] lg:mt-[45px] ">
          Baby Names
        </h1>
        <h2 className=" text-black-600 font-medium  text-sm md:text-base lg:text-lg mt-[8px] md:mt-[4px] lg:mt-[10px] ">
          Gift your baby with a perfect name
        </h2>
        <p className=" text-black-500 text-center text-sm w-[90%] md:w-[70%] lg:w-[37%] mt-[30px] md:mt-[4px] lg:mt-[10px]">
          We’re building this massive, living database of Indian baby names,
          Along with accurate name meaning, origin, pronunciation and a lot more
          - to help you choose the perfect name for your little one. Have fun!
        </p>
      </div>
    </div>
  );
}

export default HomeLandingComponent;
