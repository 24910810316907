import HeaderComponent from "../../Components/Common/Header/HeaderComponent";
import HomeLandingComponent from "../../Components/HomeLandingComponent/HomeLandingComponent";
import HomeMainComponent from "../../Components/HomeMainComponent/HomeMainComponent";
import FooterComponent from "../../Components/Common/Footer/FooterComponent";
import SEO from "../../Components/SEO/seo";

function Home() {
  return (
    <div>
      <SEO
        title="Baby Names | Name Meanings, Origin, Pronounciation & More..."
        description="The living database 1000s of Indian baby names, along with accurate name meanings, origins, pronunciations, and a lot more. [Fresh Names Updated Daily]"
      />
      <HeaderComponent />
      <HomeLandingComponent />
      <div className="md:mt-[10%] mt-[10%]">
        <HomeMainComponent />
      </div>
      <div className="mt-16">
        <FooterComponent />
      </div>
    </div>
  );
}

export default Home;
