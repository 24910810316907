import React, { useEffect, useState } from "react";
// import SideBarComponent from "./HomeSideBarComponent/SideBarComponent";
import NameListComponent from "./NameCardList/NameListComponent";
import SideBarComponent1 from "./HomeSideBarComponent/SideBarComponent1";
import { sheet2_Url } from "../../Constants/SheetUrl";
import { getDataAll } from "../../Api/api";
import FilteredCollected from "../../Feature/Filter/FilterCollected";
import multiPropsFilter from "../../Feature/Filter/MultiPropFilter";
function HomeMainComponent() {
  const [nameList, setNameList] = useState([]);
  const [nameListFiltered, setNameListFiltered] = useState([]);

  useEffect(() => {
    (async () => {
      let response = await getDataAll(sheet2_Url);
      if (response.res.data) {
        let data = response.res.data.values;
        delete data[0];
        setNameList(data);
        setNameListFiltered(data);
      }
    })();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFilterNames = (filters) => {
    //filter by Gender
    //console.log(filters);
    // console.log(filters.religion);
    const collectedTrueKeys = FilteredCollected(filters);
    const filteredProducts = multiPropsFilter(nameList, collectedTrueKeys);
    console.log("filtered products");
    console.log(filteredProducts);
    setNameListFiltered(filteredProducts);
  };

  return (
    <div className="mx-4 lg:mx-6 lg:flex lg:my-6">
      <div className="text-black-700">
        <SideBarComponent1 handleFilterSubmit={handleFilterNames} />
      </div>
      <div className="lg:ml-8 md:ml-12">
        <NameListComponent nameList={nameListFiltered} />
      </div>
    </div>
  );
}

export default HomeMainComponent;
