import React from "react";
import PublicRoute from "./PublicRoute";
import HomePage from "../Pages/Home/Home";
import Filter from "../Pages/Filter/Filter";

function Routes() {
  return (
    <div>
      <PublicRoute path="/" element={<HomePage />} />
      <PublicRoute path="/filters" element={<Filter />} />
    </div>
  );
}

export default Routes;
